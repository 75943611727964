import React, { FC } from 'react'

const FarmPageBg: FC = () => {
  return (
    <>
      <div>
        
         <img 
         className='sm:hidden absolute lg:block'
        style={{  minWidth:"110vw", marginLeft:"-40%", marginTop:"-425px" }}
        src="/images/green.png" alt="leaf" />
        
        <img 
        className='sm:hidden absolute lg:block'
        style={{ maxWidth:"200px", marginTop:"40%",marginLeft:"-150px" }}
        src="/images/bg4.png" alt="leaf" />
        <img 
        className='sm:hidden absolute lg:block'
        style={{ maxWidth:"320px", marginTop:"180px",marginLeft:"50%" }}
        src="/images/bg2.1.png" alt="leaf" />
        <img 
        className='sm:hidden absolute lg:block'
        style={{  maxWidth:"60vw" ,marginLeft:"-38%", marginTop:"-2%" }}
        src="/images/bg3.0.png" alt="leaf" />
        <img 
        className='sm:hidden absolute lg:block'
        style={{  maxWidth:"40vw" ,marginLeft:"41%", marginTop:"20%" }}
        src="/images/bground2.png" alt="leaf" />
      </div>

     
    </>
  )
}

export default FarmPageBg
